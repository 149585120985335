<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-5">
                <div class="footer_column">
                    <div class="footer_logo">
                        <img src="./assets/images/logo-white.png" alt="img">
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="footer_column">
                    <h2>About</h2>
                    <ul>
                        <li>
                            <a href="#" [routerLink]="['/about-us']">About</a>
                        </li>
                        <li>
                            <a href="#" [routerLink]="['/terms-of-services']">Terms of Services</a>
                        </li>
                        <li>
                            <a [routerLink]="['/general-guidelines']">Content Guidelines</a>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-5">
                <div class="footer_column">
                    <h2>Follow Us</h2>
                    <div class="social_links">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/avir8or/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            </li>
                            <li style="display: none;">
                                <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            </li>
                            <li style="display: none;">
                                <a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/avir8or/?hl=en" target="_blank"><i class="fab fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>                   
            </div>
        </div>
          <div class="copyright">Copyright 2020 @ <strong>AVIRATING</strong>. All rights reserved.</div>   
    </div>       
</footer>