export const environment = {
    production: true
}
//
export const apis = {
    baseUrl: 'https://api.avirating.com/api',
    // baseUrl: 'http://192.168.115.49/api',
    // baseUrl: 'http://aviratingserver.com/api',
    googleApiKey: 'AIzaSyBH2NFiXlM-Vt6Z08lo-26AvfyrOV9xvBM'

}

export const appURL = 'https://avirating.com'
// export const appURL = 'http://192.168.114.57'
